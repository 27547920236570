import React from 'react';
import '../css/CustomInput.css';

const CustomInput = (props) => {
    return (
        <div className="input-container" id={props.classID}>
            <input
                type={props.type}
                placeholder={props.placeholder}
                value={props.value}
                onChange={props.onChange}
                id={props.id}
            />
            <label htmlFor={props.id}>{props.label}</label>
        </div>
    );
}

export default CustomInput;
