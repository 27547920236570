import React from 'react';
import '../css/SortingPanel.css';
import { FiGrid, FiList } from 'react-icons/fi';

const SortingPanel = (props) => {

    return (
        <div className="sorting-container">
            <div className="sorting-box">
                <FiGrid className="sorting-icons" />
                <FiList className="sorting-icons" />

                <div className="sorting-dropdown-container" >
                    <select
                        name="sort"
                        className="sorting-dropdown"
                        onChange={(e) => { props.onChange(e) }}
                        value={props.value} >

                        <option value="default">Default sorting</option>
                        <option value="highest">Highest to Lowest Price</option>
                        <option value="lowest">Lowest to highest Price</option>
                    </select>

                    <p>VIEW: {props.num}</p>
                </div>




            </div>
        </div>
    );
}

export default SortingPanel;