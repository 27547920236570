import React, { useState } from 'react';
import '../css/Cart.css';
import { RiShoppingCartLine } from 'react-icons/ri';
import CartItem from '../components/CartItem';
import { Redirect, useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { cart, customerInfo } from '../States';

const Cart = (props) => {
    const [isVisible, setIsVisible] = useState(false);

    //global state
    const [trolley, setTrolley] = useRecoilState(cart);
    const [customer, setCustomer] = useRecoilState(customerInfo);

    let history = useHistory();

    const redirect = () => {
        setIsVisible(!isVisible);

        if (!customer.firstName) {
            history.push('/customer')
        } else {
            history.push('/cart');
        }

    }

    return (
        <div className="cart-container" >
            <div className="cart-wrapper" onClick={() => setIsVisible(!isVisible)}>
                <RiShoppingCartLine className="cart-icon" />
                {trolley.length > 0 ? <p>{trolley.length}</p> : null}
            </div>

            <div className={isVisible && trolley.length > 0 ? "cart-checkout-container" : "disappear"}>
                {trolley.map(item => {
                    return (<CartItem
                        item={item}
                    />);
                })}

                <button className="btn-primary large" onClick={redirect}>Review Order</button>

            </div>

        </div>
    );
}

export default Cart;