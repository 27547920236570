import React from 'react';
import '../css/Footer.css';
import { FiPhone, FiMail } from 'react-icons/fi';

const Footer = () => {
    return (
        <div className="footer-container">
            <div className="footer-top-panel">
                <div className="footer-panel1">
                    <h4>Contact Info</h4>
                    <p>Easy way to contact us</p>
                    <span>
                        <FiPhone className="footer-icon" />
                        <div className="footer-item">
                            <h5>Phone:</h5>
                            <p>+64 21 992 505</p>
                        </div>
                    </span>
                    <span>
                        <FiMail className="footer-icon" />
                        <div className="footer-item">
                            <h5>Email:</h5>
                            <p>support@makeiteasy.co.nz</p>
                        </div>

                    </span>
                </div>

                <div className="footer-panel2">
                    <h4>
                        Services
                    </h4>
                    <ul>
                        <li><a href="https://makeiteasy.co.nz/" >Printing / Servers</a></li>
                        <li><a href="https://makeiteasy.co.nz/" >Web Development</a></li>
                        <li><a href="https://makeiteasy.co.nz/" >Mobile App Development</a></li>
                        <li><a href="https://makeiteasy.co.nz/" >Network Solutions</a></li>
                    </ul>
                </div>

                <div className="footer-panel3">
                    <h4>
                        Useful Links
                    </h4>
                    <ul>
                        <li><a href="https://makeiteasy.co.nz/">Home</a></li>
                        <li><a href="https://makeiteasy.co.nz/about">About</a></li>
                        <li><a href="https://makeiteasy.co.nz/legal">Legal</a></li>
                        <li><a href="https://makeiteasy.co.nz/support">Support</a></li>
                    </ul>
                </div>


            </div>
            <div className="footer-copyright">
                <p>Copyright - Make IT Easy LTD 2020</p>
            </div>
        </div>
    );
}

export default Footer;