import React from 'react';
import '../css/Terms.css';

const Terms = () => {
    return (
        <div className="terms-container">
            <div className="terms-banner">
                <h2>Terms & Conditions</h2>
            </div>
            <div className="terms-content">
                <h3>Rates</h3>
                <p>The rates stated on the website are for a single hire (24hrs).</p>

                <h3>Bond</h3>
                <p>A minimum of $100 bond is taken with every order. All the equipment is your responsibility for the duration of the hire. If anything is damaged or lost, then the replacement value or cleaning/repair charge for this item will be taken from the bond.</p>

                <h3>Cleaning</h3>
                <p>Items are expected to be returned in the same condition as they were collected/delivered. All items must be cleaned properly before returning to us.</p>

                <h3>Delivery Charges</h3>
                <p>The delivery charge is dependent on where the order is going to and the quantity of items ordered. Bulky items that require more manpower and the level of difficulty in terms of access to the delivery location may incur a greater charge.</p>

                <h3>Customer Cancellation Policy</h3>
                <p>If the customer wishes to cancel hiring their rental item[s], they must inform Make Events Easy as soon as possible, in writing, in order to be eligible for a refund. Refunds are as follows:</p>

                <ul>
                    <li>If the customer cancels four weeks or more before their collection/delivery date, they will be refunded 50% of their deposit.</li>
                    <li>If the customer cancels four to one week from their collection/delivery date, they will be refunded 25% of their deposit.</li>
                    <li>If the customer cancels less than one week before their collection/delivery date, they will be given no refund of their deposit.</li>
                    <li>Due to the current state of the global coronavirus pandemic, if an event has to be cancelled due to NZ entering Level 3 or 4, we will offer a credit note valid for a period of up to 12 months from the original booking date. If the customer chooses to cancel completely, they will be charged a $50 cancellation fee.</li>
                </ul>
            </div>

            <div className="terms-content">
                <h3>Make Events Easy Cancellation</h3>
                <p>We reserve the right to cancel item collection/delivery should we consider that the customer is unsuitable to take care of the item[s] properly. We reserve the right to cancel item collection/delivery should a previous customer return the item in a damaged condition, leaving the item unfit for the next customer.</p>

                <h3>Payment</h3>
                <p>Full hire charges including bond is required within 48 hours of receiving the booking confirmation, once payment is received, bookings are confirmed. Full payment is required at least 24 hours PRIOR to pick up or delivery.</p>

                <h3>Collection</h3>
                <p>Customer collection is located at 263 Flat Bush School Road, Flat Bush, Auckland. More detailed instructions will be provided prior to the arranged collection/delivery date. Customers must check that they are happy with their item[s] at the time of collection if they observe any prior damage to their item[s].</p>

                <h3>Rescheduling Hire Date</h3>
                <p>There is no charge to the customer for changing the date of their event, on the basis that Make Events Easy have the item[s] available on the new date. We must be informed 3 days prior to the collection/delivery date if the customer wishes to change their collection/delivery date. Customers may change the date of their event once only.</p>

                <h3>DISCLAIMER</h3>
                <p>This rental agreement forms the sole agreement between the customer and Make Events Easy Hire. The customer agrees to indemnify and hold Make Events Easy harmless for any claims from the customer’s use or misuse, including any third parties for loss, injury and damage to persons or items/property arising out of the customers negligence or operation including legal costs incurred in defence of such claims.</p>

            </div>
        </div>
    );
}

export default Terms;