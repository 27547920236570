import moment from 'moment';

export function convertToJsDate(nzDate) {
    let dateArray = nzDate.split('/');

    let month = parseInt(dateArray[0]) - 1;
    let day = dateArray[1];
    let year = dateArray[2];

    // let javaDate = moment(nzDate, "D-M-YYYY").toDate();
    // return javaDate;

    return new Date(year, month.toString(), day);
}

export function convertToJsDate2(nzDate) {
    let dateArray = nzDate.split('/');

    let day = parseInt(dateArray[0]);
    let month = parseInt(dateArray[1]) - 1;
    let year = dateArray[2];

    // let javaDate = moment(nzDate, "D-M-YYYY").toDate();
    // return javaDate;

    return new Date(year, month.toString(), day);
}

export function compareDates(dateA, dateB) {
    if (dateA === undefined || dateB === undefined) throw ('Undefined date');
    if (dateA === null || dateB === null) throw ('Null date');

    if (dateA.getTime() === dateB.getTime()) {
        return 0;
    } else if (dateA < dateB) {
        return -1;
    } else {
        return 1;
    }
}