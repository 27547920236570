import React from 'react';
import '../css/ProductItem.css';
import { useHistory } from 'react-router-dom';

const ProductItem = (props) => {

    const url = `${props.item.image}`;

    let history = useHistory();

    const redirect = () => {
        history.push('/products/' + props.item.id)
    }

    const addToCart = () => {
        props.onClick(props.item);
    }

    return (
        <div className="item-container">
            <div className="item-image-wrapper" onClick={redirect}>
                <img src={url} alt="Product" />
                <div className="item-quick-view">
                    <h3>QUICK VIEW</h3>
                </div>

            </div>

            <p className="item-category">{props.item.category}</p>
            <p className="item-title">{props.item.title}</p>
            <p className="item-price">${props.item.price}<span> {props.item.eaches ? "/each" : "/per day"}</span></p>
            <button className="btn-primary" onClick={addToCart}>Add to cart</button>


        </div>
    );
}

export default ProductItem;