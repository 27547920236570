import React, { useState, useEffect } from 'react';
import '../css/Finalise.css';
import { useHistory } from 'react-router-dom';
import { css } from "@emotion/core";
import BeatLoader from "react-spinners/BeatLoader";
import { useRecoilState } from 'recoil';
import { customerInfo, cart } from '../States';
import { placeOrder, getCurrentOrderId } from '../api/FirebaseAPI';
import axios from 'axios';


const Finalise = (props) => {
    let history = useHistory();

    const [loading, setLoading] = useState(true);
    const [result, setResult] = useState();

    //global
    const [customer, setCustomer] = useRecoilState(customerInfo);
    const [trolley, setTrolley] = useRecoilState(cart);

    useEffect(() => {
        if (trolley.length <= 0) {
            setResult("No items found in your cart");
            setLoading(false);
            return;
        }
        saveOrder();
    }, []);

    if (!customer.firstName) {
        history.push('/');
    }

    const saveOrder = async () => {

        const orderId = await getCurrentOrderId();

        let data = {
            cart: trolley,
            firstName: customer.firstName,
            lastName: customer.lastName,
            address: customer.address,
            apt: customer.apt,
            suburb: customer.suburb,
            country: customer.country,
            email: customer.email,
            number: customer.number,
            amount: customer.amount,
            pickupDate: customer.pickupDate,
            pickupTime: customer.pickupTime,
            dropOffDate: customer.dropOffDate,
            dropOffTime: customer.dropOffTime,
            bond: customer.bond,
            uri: customer.uri,
            delivery: customer.delivery,
            discount: customer.discount,
            orderId: orderId,
        };

        placeOrder(data, () => {

            //send confirmation email
            axios.post('https://makeiteasy.co.nz/orderplaced/index.php', data)
                .then(response => {
                    setResult(response.data);
                    setLoading(false);
                    setTrolley([]);
                }).catch(error => {
                    setResult(error.toString());
                })

        }, error => {
            setResult("Error: " + error.code + " :: " + error.message);
            setLoading(false);
        })
    }

    return (
        <div className="final-container">
            <div className="final-card">
                <h4>Processing your order</h4>
                {loading ? <div className="final-loader">
                    <BeatLoader
                        size={50}
                        color={"#1e73be"}
                        loading={loading}
                    />
                </div> : null}

                {!loading ? <div className="final-message">
                    {result}
                </div> : null}

            </div>
        </div>
    );
}

export default Finalise;