import React from 'react';
import '../css/CustomCalendar.css';
import { FaCalendarAlt } from 'react-icons/fa';

const CustomCalendar = (props) => {
    return (
        <div className="calendar-container" onClick={() => { props.onClick() }}>
            <input value={props.value} readOnly />
            <FaCalendarAlt className="calendar-icon" />
        </div>
    );
}

export default CustomCalendar;