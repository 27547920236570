import React, { useState, useEffect } from 'react';
import '../css/Navbar.css';
import { Link } from 'react-router-dom';
import Cart from '../components/Cart.js';
import Sidebar from '../components/Sidebar';
import { adminToken } from '../States';
import { useRecoilState } from 'recoil';
import { setAuthObserver } from '../api/FirebaseAPI';

const Navbar = (props) => {

    const [admin, setAdmin] = useRecoilState(adminToken);

    useEffect(() => {
        //check if admin is loggedIn
        setAuthObserver(user => {
            setAdmin(user.uid);
        },
            () => {
                setAdmin('none');
            })
    }, []);

    return (
        <div className="nav-container">

            <Sidebar />

            <img className="nav-item nav-image" src={require('../assets/eventlogo.png')} alt="log" />
            <Link className="nav-item" to="/">Products</Link>
            <Link className="nav-item" to="/customer">Details</Link>
            <Link className="nav-item" to="/terms">Terms & Condtions</Link>
            {admin !== 'none' ? <Link className="nav-item" to="/orders">Orders</Link> : null}
            <Cart />
            <div className="nav-item-end">
                <Link className="nav-item" to="/login">Admin</Link>
            </div>
        </div>
    );
}

export default Navbar;