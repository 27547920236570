import React, { useState, useEffect } from 'react';
import "../css/Orders.css";
import { adminToken } from '../States';
import { useRecoilState } from 'recoil';
import { getDatabase } from '../api/FirebaseAPI';
import GroupedItems from '../components/GroupedItems';
import { convertToJsDate2, compareDates } from '../util/Dates';



const Orders = () => {
    const [orders, setOrders] = useState([]);
    const [message, setMessage] = useState('No orders found');

    const [unconfirmed, setUnconfirmed] = useState([]);
    const [pending, setPending] = useState([]);
    const [overdue, setOverdue] = useState([]);
    const [completedList, setCompletedList] = useState([]);

    //global
    const [admin, setAdmin] = useRecoilState(adminToken);

    useEffect(() => {

        const unsub = getDatabase().collection('orders')
            .onSnapshot(snapshot => {
                let list = [];

                if (snapshot.size) {
                    snapshot.forEach(doc => {
                        list.push({ ...doc.data(), id: doc.id })
                    });

                    // console.log("list: ", list);
                    setOverdue(filterOverdueOrders(list));
                    setUnconfirmed(filterUnconfirmedOrders(list));
                    setPending(filterPendingOrders(list));
                    setCompletedList(filterCompleteOrders(list))
                    setOrders(list);
                } else {
                    //empty
                }
            });


        return () => {
            unsub();
        }

    }, []);

    useEffect(() => {
        if (admin === 'none') {
            setMessage("Please login to access this page");
        }
        else {

        }
    }, [admin]);

    const filterOverdueOrders = (list) => {

        if (list.length <= 0) return;
        if (!list) return;

        let today = new Date();

        let overdue = list.filter(order => {
            if (!order) return false;

            if (order.completed) return false;

            let due = convertToJsDate2(order.dropOffDate);
            if (compareDates(today, due) >= 0 && order.confirmed) {
                return true;
            } else {
                return false;
            }
        });

        return overdue.sort((a, b) => {
            let d1 = convertToJsDate2(a.dropOffDate);
            let d2 = convertToJsDate2(b.dropOffDate);
            if (compareDates(d1, d2) < 0) {
                return 1;
            } else if (compareDates(d1, d2) > 0) {
                return -1;
            } else {
                return 0;
            }
        });

    }

    const filterUnconfirmedOrders = (list) => {
        if (list.length <= 0) return;
        if (!list) return;

        let unconfirmed = list.filter(order => {
            if (!order) return false;

            if (order.confirmed) {
                return false;
            } else {
                let due = convertToJsDate2(order.dropOffDate);
                console.log("due: ", due);
                return true;
            }
        });

        return unconfirmed.sort((a, b) => {
            let d1 = convertToJsDate2(a.dropOffDate);
            let d2 = convertToJsDate2(b.dropOffDate);
            if (compareDates(d1, d2) < 0) {
                return 1;
            } else if (compareDates(d1, d2) > 0) {
                return -1;
            } else {
                return 0;
            }
        });


    }

    const filterPendingOrders = list => {
        if (list.length <= 0) return;
        if (!list) return;

        let today = new Date();

        let pending = list.filter(order => {
            if (!order) return false;

            if (order.completed) return false;

            let due = convertToJsDate2(order.dropOffDate);
            if (compareDates(today, due) < 0 && order.confirmed) {
                return true;
            } else {
                return false;
            }
        });

        return pending.sort((a, b) => {
            let d1 = convertToJsDate2(a.dropOffDate);
            let d2 = convertToJsDate2(b.dropOffDate);
            return compareDates(d1, d2);
        });

    }


    const filterCompleteOrders = (list) => {
        if (list.length <= 0) return;
        if (!list) return;

        let completed = list.filter(order => {
            if (!order) return false;

            if (order.completed) {
                return true;
            } else {
                return false;
            }
        });


        //most recent at the top
        return completed.sort((a, b) => {
            let d1 = convertToJsDate2(a.dropOffDate);
            let d2 = convertToJsDate2(b.dropOffDate);
            if (compareDates(d1, d2) < 0) {
                return 1;
            } else if (compareDates(d1, d2) > 0) {
                return -1;
            } else {
                return 0;
            }
        });


    }



    return (
        <div className="orders-container">

            <GroupedItems
                items={unconfirmed}
                title="Unconfirmed"
            />

            <GroupedItems
                items={pending}
                title="Confirmed (pending)"
                bg={'rgba(30, 255, 0, 0.137)'}
                titleColor="#00bd33"
            />

            <GroupedItems
                items={overdue}
                title="Confirmed (overdue)"
                bg={'rgba(255, 8, 0, 0.137)'}
                titleColor="#bd2300"
            />

            <GroupedItems
                items={completedList}
                title="Completed"
                bg={'rgba(133, 17, 186, 0.2)'}
                titleColor="#8511BA"
            />

            <GroupedItems
                items={orders}
                title='All Orders'
                bg={'rgba(255, 115, 0, 0.137)'}
                titleColor="#f58700"
            />



            {orders.length === 0 ? <p key="1">{message}</p> : null}
        </div>

    );
}

export default Orders;