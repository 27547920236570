import React, { useState } from 'react';
import '../css/GroupedItems.css';
import OrderItem from '../components/OrderItem';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';


const GroupedItems = ({ items, title, bg, titleColor }) => {

    const [collapse, setCollapse] = useState(true);

    return (
        <div className="group-container" style={{ backgroundColor: bg }}>
            <div className="group-header" style={{ color: titleColor }} onClick={() => setCollapse(!collapse)}>
                <h4 >{title && title} ({items && items.length})</h4>
                {collapse ? <AiFillCaretUp /> : <AiFillCaretDown />}
            </div>
            {!collapse ?
                items.map(item => {
                    return (
                        <OrderItem
                            key={item.id}
                            data={item}
                        />
                    )
                }) : null
            }
        </div>
    );
}

export default GroupedItems;