import React, { useState } from 'react';
import '../css/Sidebar.css';
import { HiOutlineMenuAlt1 } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { adminToken } from '../States';
import { useRecoilState } from 'recoil';

const Sidebar = () => {
    const [active, setActive] = useState(false);

    //global
    const [admin, setAdmin] = useRecoilState(adminToken);

    const toggle = (e) => {
        setActive(!active);
        e.preventDefault();
    }

    return (
        <div className="sidebar-container" onClick={toggle} >

            <div className="sidebar-icon" >
                <HiOutlineMenuAlt1 />
            </div>

            <div className={!active ? "sidebar-menu" : "sidebar-menu fadeIn"} onClick={toggle}>
                <div className={!active ? "sidebar-header" : "sidebar-header slideIn"}>
                    <img className="sidebar-image" src={require('../assets/eventlogo.png')} alt="log" />
                </div>

                <div className={!active ? "sidebar-main" : "sidebar-main slideIn"}>
                    <Link className="sidebar-item" to="/">Products</Link>
                    <Link className="sidebar-item" to="/customer">Details</Link>
                    <Link className="sidebar-item" to="/terms">Terms & Conditions</Link>
                    <Link className="sidebar-item" to="/login">Admin</Link>
                    {admin !== 'none' ? <Link className="sidebar-item sidebar-submenu" to="/orders">Orders</Link> : null}
                </div>
            </div>

        </div>
    );
}

export default Sidebar;