import React, { useState, useEffect } from 'react';
import '../css/Login.css';
import CustomInput from '../components/CustomInput';
import { signIn, signOut } from '../api/FirebaseAPI';
import { useHistory } from 'react-router-dom';
import { adminToken } from '../States';
import { useRecoilState } from 'recoil';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');


    const [admin, setAdmin] = useRecoilState(adminToken);

    let history = useHistory();

    const updateEmail = e => {
        setEmail(e.target.value);
        setError('');
    }

    const updatePassword = e => {
        setPassword(e.target.value);
        setError('');
    }

    const login = () => {
        signIn(email, password, () => {
            history.push("/");
        }, (error) => {
            setError('Error: ' + error.code + ' :: ' + error.message);
        });
    }

    const logout = () => {
        signOut(() => {
            history.push("/");
        }, () => {
            history.push('/login');
        })
    }

    return (
        <div className="login-container">
            {
                admin !== 'none' ?
                    <div className="login-persis">
                        <p>You are already logged in as the Administrator</p>
                        <p className="login-link" onClick={logout}>Logout</p>
                        <p className="login-link" onClick={() => { history.push("/") }}>Shop</p>
                    </div>
                    :
                    <div className="login-card">
                        <div className="login-header">
                            <h3>Administrator Login</h3>
                        </div>
                        <div className="login-main">
                            {/* <label htmlFor="email">Email</label>
                    <input type="text" placeholder="Email" id="email" onChange={updateEmail} value={email} />
                    <label htmlFor="password">Email</label>
                    <input type="password" placeholder="Password" id="password" onChange={updatePassword} value={password} /> */}
                            <CustomInput
                                type="text"
                                label="Email"
                                placeholder="Email"
                                onChange={updateEmail}
                                value={email}
                                id="email"
                            />
                            <CustomInput
                                type="password"
                                label="Password"
                                placeholder="Password"
                                onChange={updatePassword}
                                value={password}
                                id="password"
                            />
                            <button className="btn-primary login-button" onClick={login}>Login</button>
                        </div>
                        <div className="login-footer">
                            <p>{error}</p>
                        </div>
                    </div>
            }

        </div>
    );
}

export default Login;