import React from 'react';
import '../css/CartItem.css';
import { useRecoilState } from 'recoil';
import { cart, customerInfo } from '../States';

const CartItem = (props) => {
    const [trolley, setTrolley] = useRecoilState(cart);
    const [customer, setCustomer] = useRecoilState(customerInfo);

    const onRemove = () => {
        let array = [...trolley];
        let index;
        for (index = 0; 0 < array.length; index++) {
            if (array[index].pos === props.item.pos) {
                array.splice(index, 1);
                setTrolley(array);
                break;
            }
        }
    }

    return (
        <div className="cartitem-container">
            <img src={props.item.image} />
            <div className="cartitem-infowrapper">
                <h4>{props.item.title}</h4>
                <div className="cartitem-price">
                    <p>{props.item.quantity} x </p>
                    <h5>${props.item.price}</h5>
                </div>
                <span><h5>Pickup date & time:</h5>{customer.pickupDate} | {customer.pickupTime}</span>
                <span><h5>Drop off date & time:</h5>{customer.dropOffDate} | {customer.dropOffTime}</span>
            </div>
            <div className="cartitem-remove" onClick={onRemove}>x</div>
        </div>
    );
}

export default CartItem;