import React, { useState, useEffect } from 'react';
import '../css/Products.css';
import '../components/SortingPanel';
import SortingPanel from '../components/SortingPanel';
import { getDatabase } from '../api/FirebaseAPI';
import ProductItem from '../components/ProductItem';
import { cart } from '../States';
import { useRecoilState } from 'recoil';


const Products = (props) => {
    const [sort, setSort] = useState('default');
    const [products, setProducts] = useState([]);

    //global
    const [trolley, setTrolley] = useRecoilState(cart);
    useEffect(() => {

        let ref = getDatabase().collection('products').orderBy('productCode');

        return ref.onSnapshot(querySnapshot => {
            const list = [];

            querySnapshot.forEach(doc => {
                let data = doc.data();

                list.push({
                    ...data, id: doc.id
                });
            });

            setProducts(list);
        });

    }, [])

    const onSortChange = (e) => {
        setSort(e.target.value);
    }

    const getProductCount = () => {
        return products.length;
    }

    const addToCart = (product) => {

        let item = {
            ...product,
            quantity: 1,
            pos: getCartPos(),
        };

        //check if item already exsits
        for (let i = 0; i < trolley.length; i++) {
            if (trolley[i].productCode === item.productCode) {
                let newTrolley = [...trolley];
                item.quantity += trolley[i].quantity;
                newTrolley[i] = item;
                setTrolley(newTrolley);
                return;
            }
        }

        setTrolley([...trolley, item]);
    }

    const getCartPos = () => {
        let pos = 1;
        if (trolley.length > 0) {
            pos = trolley[trolley.length - 1].pos + 1;
        }

        return pos;
    }

    return (
        <div className="product-container">
            <div className="product-banner">
                <h2>Products</h2>
                <p>Select the products you wish to add to your cart.</p>
            </div>

            <SortingPanel
                value={sort}
                onChange={onSortChange}
                num={getProductCount()}
            />

            <div className="product-area">
                {products.map(item => {
                    return (
                        <ProductItem
                            item={item}
                            key={item.id}
                            onClick={addToCart}
                        />
                    );
                })}
            </div>


        </div>
    );
}

export default Products;