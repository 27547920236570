import React, { useState, useEffect } from 'react';
import '../css/Uploader.css';
import { getStorageRef } from '../api/FirebaseAPI';
import ProgressBar from '../components/ProgressBar';
import { useRecoilState } from 'recoil';
import { customerInfo } from '../States';

const Uploader = (props) => {
    const [image, setImage] = useState(null);
    const [filename, setFilename] = useState('No file selected');
    const [url, setUrl] = useState();
    const [progress, setProgress] = useState(0);
    const [error, setError] = useState('');

    //global state
    const [customer, setCustomer] = useRecoilState(customerInfo);

    const handleUploadCompleted = (uri) => {
        props.uploadComplete(uri);
    }

    const handleChange = e => {
        setError('');

        if (e.target.files[0]) {

            const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
            if (!validImageTypes.includes(e.target.files[0].type)) {
                setError('Please use valid image type .jpeg, .png');
                return;
            }
            setFilename(e.target.files[0].name);
            setImage(e.target.files[0]);
        }

    }

    const handleUpload = () => {
        if (!image) return;
        let ref = getStorageRef();
        let uploadTask = ref.child('id/' + image.name).put(image);

        uploadTask.on('state_changed',
            (snapshot) => {
                var p = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setProgress(p);
            },
            (error) => {
                setError(error.code);
                console.log("upload error: ", error);
            },
            () => {
                uploadTask.snapshot.ref.getDownloadURL().then(uri => {
                    setUrl(uri);
                    handleUploadCompleted(uri);
                    console.log("image link: ", uri);
                });
            }
        );
    }


    return (
        <div className="uploader-container">
            <h4>Upload Photo ID</h4>
            <div className="uploader-input">
                <label htmlFor="file-upload" className="uploader-chooser">
                    <h5>Choose file</h5>
                    <p>{filename}</p>
                </label>
                <input id="file-upload" type="file" onChange={handleChange} />
                <button className="btn-primary" onClick={handleUpload}>Upload</button>
            </div>
            <ProgressBar progress={progress + '%'} />
            {url ? <img src={url} style={{ width: 100, height: 100 }} /> : null}
            {error !== '' ? <p className="home-error">{error}</p> : null}
        </div>
    );
}

export default Uploader;
