import React from 'react';

const EditInput = ({ id, label, onChange, type, placeholder, value, containerStyle, inputStyle, labelStyle, readOnly }) => {
    return (
        <div className={containerStyle}>
            <label htmlFor={id} className={labelStyle}>{label}</label>
            <input name={id} type={type} placeholder={placeholder} value={value} onChange={onChange} className={inputStyle} readOnly={readOnly} />
        </div>
    );
}

export default EditInput;