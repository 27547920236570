import React, { useState, useEffect } from 'react';
import '../css/Edit.css';
import { dataHolder } from '../States';
import { useRecoilState } from 'recoil';
import { useHistory, Link } from 'react-router-dom';
import EditInput from '../components/EditInput';
import CustomCalendar2 from '../components/CustomCalendar2';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from 'react-time-picker';
import { convertToJsDate, convertToJsDate2 } from '../util/Dates';
import { getDatabase } from '../api/FirebaseAPI';
import BeatLoader from "react-spinners/BeatLoader";
import { FaWindowClose } from 'react-icons/fa';
import axios from 'axios';
import moment from 'moment';

const Edit = () => {

    const [data, setData] = useRecoilState(dataHolder);

    let history = useHistory();

    //customer data
    const [fname, setFname] = useState();
    const [lname, setLname] = useState();
    const [address, setAddress] = useState();
    const [apt, setApt] = useState();
    const [suburb, setSuburb] = useState();
    const [city, setCity] = useState();
    const [email, setEmail] = useState();
    const [number, setNumber] = useState();
    const [pickupDate, setPickupDate] = useState(new Date());
    const [pickupTime, setPickupTime] = useState('10:00');
    const [dropOffDate, setDropOffDate] = useState(new Date());
    const [dropOffTime, setDropOffTime] = useState('10:00');
    const [bond, setBond] = useState(0);
    const [uri, setUri] = useState(null);
    const [amount, setAmount] = useState(0);
    const [confirmed, setConfirmed] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [delivery, setDelivery] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [orderId, setOrderId] = useState(-1);
    const [cart, setCart] = useState([]);

    const [selectedItem, setSelectedItem] = useState();
    const [products, setProducts] = useState([]);
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState('');


    useEffect(() => {
        if (data) {
            setItem();
        }
    }, [data]);

    useEffect(() => {

        let mTotal = 0;

        for (let i = 0; i < cart.length; i++) {
            mTotal += parseFloat(cart[i].totalPrice);
        }

        mTotal += parseFloat(delivery) + parseFloat(bond);
        if (discount) mTotal -= parseFloat(discount);

        setTotal(mTotal);

    }, [cart, amount, bond, delivery, discount])

    useEffect(() => {
        let ref = getDatabase().collection('products').orderBy('productCode');

        return ref.onSnapshot(querySnapshot => {
            const list = [];

            querySnapshot.forEach(doc => {
                let data = doc.data();

                list.push({
                    ...data, id: doc.id
                });
            });

            setProducts(list);
        });
    }, []);


    const setItem = () => {
        setFname(data.firstName);
        setLname(data.lastName);
        setAddress(data.address);
        setApt(data.apt);
        setCity(data.country);
        setSuburb(data.suburb);
        setEmail(data.email);
        setNumber(data.number);
        setAmount(data.amount);
        setBond(data.bond);
        setDelivery(data.delivery);
        setDiscount(data.discount);
        setOrderId(data.orderId);

        let d1 = convertToJsDate2(data.pickupDate);
        let d2 = convertToJsDate2(data.dropOffDate);

        console.log("Debug date: ", d1);
        console.log("Debug date: ", d2);

        setPickupDate(new Date(d1));
        setPickupTime(data.pickupTime);
        setDropOffDate(new Date(d2));
        setDropOffTime(data.dropOffTime);
        setUri(data.uri);

        if (data.confirmed) setConfirmed(true);
        if (data.completed) setCompleted(true);
        setCart(data.cart);
    }

    const amountChanged = (amount) => {
        let newCart = [...cart];

        for (let i = 0; i < newCart.length; i++) {
            let newItem = { ...newCart[i] };
            if (newItem.eaches) {
                newItem.totalPrice = parseFloat(newCart[i].quantity) * parseFloat(newCart[i].price);
            } else {
                newItem.totalPrice = parseFloat(newCart[i].quantity) * parseFloat(newCart[i].price) * parseFloat(amount);
            }
            newCart[i] = newItem;
        }

        setCart(newCart);
        setAmount(amount);

    }

    const changeItemPrice = (item, quantity) => {
        let newCart = [...cart];
        let newItem = { ...item };
        newItem.quantity = quantity;
        newItem.totalPrice = parseFloat(quantity) * parseFloat(item.price) * parseFloat(amount);

        for (let i = 0; i < newCart.length; i++) {
            if (newCart[i].pos === item.pos) {
                newCart[i] = newItem;
                break;
            }
        }

        setCart(newCart);
    }

    const addItem = () => {
        if (!selectedItem) return;

        let newProduct = {};
        let pos = 0;

        if (cart.length > 0) {
            pos = cart[cart.length - 1].pos + 1;
        }

        for (let i = 0; i < products.length; i++) {
            if (selectedItem === products[i].productCode) {

                let tp = 0;
                if (products[i].eaches) {
                    tp = products[i].price * 1
                } else {
                    tp = products[i].price * 1 * amount;
                }

                newProduct = {
                    ...products[i],
                    quantity: 1,
                    totalPrice: tp,
                    pos: pos
                }
            }
        }

        let newCart = [...cart];
        newCart.push(newProduct);

        setCart(newCart);
        setSelectedItem(null);
    }

    const removeItem = (pos) => {
        let array = [...cart];
        let index;
        for (index = 0; 0 < array.length; index++) {
            if (array[index].pos === pos) {
                array.splice(index, 1);
                setCart(array);
                break;
            }
        }
    }

    const save = () => {
        let newData = {
            ...data,
            firstName: fname,
            lastName: lname,
            address: address,
            apt: apt,
            country: city,
            suburb: suburb,
            email: email,
            number: number,
            amount: amount,
            bond: bond,
            delivery: delivery,
            discount: discount,
            pickupDate: moment(pickupDate).format('D/M/YYYY'),
            pickupTime: pickupTime,
            dropOffDate: moment(dropOffDate).format('D/M/YYYY'),
            dropOffTime: dropOffTime,
            uri: uri,
            confirmed: confirmed,
            completed: completed,
            cart: cart,
            orderId: orderId,
        }

        console.log("new data: ", newData);



        setLoading(true);
        const db = getDatabase();
        db.collection('orders').doc(data.id).set(newData)
            .then(() => {
                setLoading(false);
                setResponse('save successful');
                setData(null);
                history.push('/orders');
            }).catch(error => {
                setLoading(false);
                setResponse('Error:: ' + error.code);
            })
    }

    const saveAndSend = () => {
        let newData = {
            ...data,
            firstName: fname,
            lastName: lname,
            address: address,
            apt: apt,
            country: city,
            suburb: suburb,
            email: email,
            number: number,
            amount: amount,
            bond: bond,
            delivery: delivery,
            discount: discount,
            pickupDate: moment(pickupDate).format('D/M/YYYY'),
            pickupTime: pickupTime,
            dropOffDate: moment(dropOffDate).format('D/M/YYYY'),
            dropOffTime: dropOffTime,
            uri: uri,
            confirmed: confirmed,
            completed: completed,
            cart: cart,
            orderId: orderId,
        }

        setLoading(true);
        const db = getDatabase();
        db.collection('orders').doc(data.id).set(newData)
            .then(() => {
                confirmation(newData);

            }).catch(error => {
                setLoading(false);
                setResponse('Error:: ' + error.code);
            })


    }

    const confirmation = (data) => {

        axios.post('https://makeiteasy.co.nz/finalise/index.php', data)
            .then(response => {
                console.log("Axios response: ", response.data);
                setResponse(response.data);
                setLoading(false);
                setData(null);
                updateConfirmationStatus();
                history.push('/orders');
            }).catch(error => {
                console.log("Axios error: ", error);
            });

    }

    const updateConfirmationStatus = () => {
        const db = getDatabase();

        db.collection('orders').doc(data.id).update({
            confirmed: true
        });
    }

    const cancel = () => {
        setData(null);
        history.push('/orders');
    }

    if (!data) {
        return (
            <div className="edit-container">
                <p className="edit-error">Unauthorised access please navigate to <Link to="/orders">back</Link> orders</p>
            </div>
        )
    }

    return (
        <div className="edit-container">
            <h3>Edit Order Details</h3>
            <EditInput
                containerStyle="edit-input-container"
                labelStyle="edit-input-label"
                inputStyle="edit-input-text"
                id="fname"
                label="First Name:"
                type="text"
                value={fname}
                onChange={(e) => { setFname(e.target.value) }}
            />

            <EditInput
                containerStyle="edit-input-container"
                labelStyle="edit-input-label"
                inputStyle="edit-input-text"
                id="lname"
                label="Last Name:"
                type="text"
                value={lname}
                onChange={(e) => { setLname(e.target.value) }}
            />

            <EditInput
                containerStyle="edit-input-container"
                labelStyle="edit-input-label"
                inputStyle="edit-input-text"
                id="apt"
                label="Apartment Number:"
                type="text"
                value={apt}
                onChange={(e) => { setApt(e.target.value) }}
            />

            <EditInput
                containerStyle="edit-input-container"
                labelStyle="edit-input-label"
                inputStyle="edit-input-text"
                id="address"
                label="Address:"
                type="text"
                value={address}
                onChange={(e) => { setAddress(e.target.value) }}
            />

            <EditInput
                containerStyle="edit-input-container"
                labelStyle="edit-input-label"
                inputStyle="edit-input-text"
                id="suburb"
                label="Suburb:"
                type="text"
                value={suburb}
                onChange={(e) => { setSuburb(e.target.value) }}
            />

            <EditInput
                containerStyle="edit-input-container"
                labelStyle="edit-input-label"
                inputStyle="edit-input-text"
                id="city"
                label="City:"
                type="text"
                value={city}
                onChange={(e) => { setCity(e.target.value) }}
            />

            <EditInput
                containerStyle="edit-input-container"
                labelStyle="edit-input-label"
                inputStyle="edit-input-text"
                id="email"
                label="Email Address:"
                type="text"
                value={email}
                onChange={(e) => { setEmail(e.target.value) }}
            />

            <EditInput
                containerStyle="edit-input-container"
                labelStyle="edit-input-label"
                inputStyle="edit-input-text"
                id="phone"
                label="Phone Number:"
                type="text"
                value={number}
                onChange={(e) => { setNumber(e.target.value) }}
            />

            <div className="edit-input-container">
                <span className="edit-input-label">Pickup Date:</span>
                <DatePicker
                    selected={pickupDate}
                    onChange={date => setPickupDate(date)}
                    customInput={<CustomCalendar2 extraClass="edit-datepicker" />}
                    dateFormat='dd/MM/yyy'
                />
            </div>

            <div className="edit-input-container">
                <span className="edit-input-label">Pickup Time:</span>
                <TimePicker
                    onChange={setPickupTime}
                    value={pickupTime}
                    disableClock={true}
                    className="edit-clock"
                    dateFormat='dd/MM/yyy'
                />
            </div>

            <div className="edit-input-container">
                <span className="edit-input-label">Drop Off Date:</span>
                <DatePicker
                    selected={dropOffDate}
                    onChange={date => setDropOffDate(date)}
                    customInput={<CustomCalendar2 extraClass="edit-datepicker" />}
                    dateFormat='dd/MM/yyy'
                />
            </div>

            <div className="edit-input-container">
                <span className="edit-input-label">Drop Off Time:</span>
                <TimePicker
                    onChange={setDropOffTime}
                    value={dropOffTime}
                    disableClock={true}
                    className="edit-clock"
                    dateFormat='dd/MM/yyy'
                />
            </div>

            <EditInput
                containerStyle="edit-input-container"
                labelStyle="edit-input-label"
                inputStyle="edit-input-text"
                id="days"
                label="Days of hire:"
                type="number"
                value={amount}
                onChange={(e) => { amountChanged(e.target.value) }}
            />

            <EditInput
                containerStyle="edit-input-container"
                labelStyle="edit-input-label"
                inputStyle="edit-input-text"
                id="bond"
                label="Bond:"
                type="number"
                value={bond}
                onChange={(e) => { setBond(e.target.value) }}
            />

            <EditInput
                containerStyle="edit-input-container"
                labelStyle="edit-input-label"
                inputStyle="edit-input-text"
                id="delivery"
                label="Delivery Fee:"
                type="number"
                value={delivery}
                onChange={(e) => { setDelivery(e.target.value) }}
            />

            <EditInput
                containerStyle="edit-input-container"
                labelStyle="edit-input-label"
                inputStyle="edit-input-text"
                id="discount"
                label="Discount:"
                type="number"
                value={discount}
                onChange={(e) => { setDiscount(e.target.value) }}
            />

            <div className="edit-input-container">
                <label className="edit-input-label" htmlFor="confirmed">Confirmed:</label>

                <select
                    name="confirmed"
                    id="confirmed"
                    className="edit-input-text"
                    onChange={(e) => { setConfirmed(e.target.value === "false" ? false : true) }}
                    value={confirmed}
                >
                    <option value={true} >True</option>
                    <option value={false} >False</option>
                </select>
            </div>

            <div className="edit-input-container">
                <label className="edit-input-label" htmlFor="confirmed">Completed:</label>

                <select
                    name="completed"
                    id="completed"
                    className="edit-input-text"
                    onChange={(e) => { setCompleted(e.target.value === "false" ? false : true) }}
                    value={completed}
                >
                    <option value={true} >True</option>
                    <option value={false} >False</option>
                </select>
            </div>

            <EditInput
                containerStyle="edit-input-container"
                labelStyle="edit-input-label"
                inputStyle="edit-input-text"
                id="orderId"
                label="Order Id:"
                type="number"
                value={orderId}
                onChange={(e) => { setOrderId(e.target.value) }}
            />

            <hr className="edit-divider" />

            <h3>Cart Items</h3>
            <div className="edit-input-container">
                {cart.map(item => {
                    return (
                        <div className="edit-cart-item">
                            <input type="number" value={item.quantity} onChange={(e) => { changeItemPrice(item, e.target.value) }} />
                            <p>{item.title} @ ${item.price}</p>
                            <span> - ${item.totalPrice}</span>
                            <FaWindowClose onClick={() => { removeItem(item.pos) }} className="edit-remove-item" />
                        </div>
                    )
                })}

                <div className="edit-cart-item">
                    <select
                        name="add"
                        id="add"
                        className="edit-input-dropdown"
                        onChange={(e) => { setSelectedItem(e.target.value) }}
                        value={selectedItem}
                    >
                        <option value="" disabled selected >Select a product you wish to add</option>
                        {products.map(item => {
                            return (
                                <option key={item.id} value={item.productCode}>
                                    {item.title} @ ${item.price} per unit
                                </option>
                            )
                        })}


                    </select>

                    <button className="btn-primary" onClick={addItem}>Add</button>
                </div>

                <EditInput
                    containerStyle="edit-input-container"
                    labelStyle="edit-input-label"
                    inputStyle="edit-input-text readonly"
                    id="total"
                    label="Total:"
                    type="text"
                    readOnly={true}
                    value={"$" + total}
                />
            </div>

            <div className="edit-buttons">
                <button className="btn-primary" onClick={save}>Save</button>
                <button className="btn-primary" onClick={saveAndSend}>Save & Resend</button>
                <button className="btn-secondary" onClick={cancel}>Cancel</button>
            </div>

            <div className="edit-loader">
                {loading ? <BeatLoader
                    size={10}
                    color={"#1e73be"}
                    loading={loading}
                /> : response}
            </div>
        </div>
    );
}

export default Edit;