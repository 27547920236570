import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import '../css/Home.css';
import CustomInput from '../components/CustomInput';
import { useRecoilState } from 'recoil';
import { customerInfo, cart } from '../States';
import CustomCalendar from '../components/CustomCalendar';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from 'react-time-picker';
import Uploader from '../components/Uploader';
import { convertToJsDate, convertToJsDate2 } from '../util/Dates';
import moment from 'moment';

const Home = (props) => {
    const [showForm, setShowForm] = useState(false);
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [address, setAddress] = useState('');
    const [apt, setApt] = useState('');
    const [suburb, setSuburb] = useState('');
    const [country, setCountry] = useState('');
    const [email, setEmail] = useState('');
    const [num, setNumber] = useState('');
    const [error, setError] = useState('');
    const [pickupDate, setPickupDate] = useState(new Date());
    const [pickupTime, setPickupTime] = useState('10:00');
    const [dropOffDate, setDropOffDate] = useState(new Date());
    const [dropOffTime, setDropOffTime] = useState('10:00');
    const [uri, setUri] = useState(null);

    const [redirect, setRedirect] = useState(false);

    //global state
    const [customer, setCustomer] = useRecoilState(customerInfo);
    const [trolley, setTrolley] = useRecoilState(cart);

    let history = useHistory();

    const updateFname = (e) => {
        e.preventDefault();
        setFname(e.target.value);
        setError('');
    }

    const updateLname = (e) => {
        e.preventDefault();
        setLname(e.target.value);
        setError('');
    }

    const updateAddress = (e) => {
        e.preventDefault();
        setAddress(e.target.value);
        setError('');
    }

    const updateApt = (e) => {
        e.preventDefault();
        setApt(e.target.value);
        setError('');
    }

    const updateSuburb = (e) => {
        e.preventDefault();
        setSuburb(e.target.value);
        setError('');
    }

    const updateCountry = (e) => {
        e.preventDefault();
        setCountry(e.target.value);
        setError('');
    }

    const updateEmail = (e) => {
        e.preventDefault();
        setEmail(e.target.value);
        setError('');
    }

    const updateNumber = (e) => {
        e.preventDefault();
        setNumber(e.target.value);
        setError('');
    }

    const calculateDays = () => {

        let days = dropOffDate - pickupDate;
        days = Math.round(days / 24 / 60 / 60 / 1000);

        return days;
    }

    const calculateTime = () => {
        let p1 = pickupTime.split(':');
        let pH = parseInt(p1[0]);
        let pM = parseInt(p1[1]);
        let pTime = (pH * 60) + pM;

        let d1 = dropOffTime.split(':');
        let dH = parseInt(d1[0]);
        let dM = parseInt(d1[1]);
        let dTime = (dH * 60) + dM;

        return dTime - pTime;
    }

    const validateData = () => {
        if (fname.trim() === '') {
            setError('Please fill all fields');
            return;
        }
        if (lname.trim() === '') {
            setError('Please fill all fields');
            return;
        }
        if (address.trim() === '') {
            setError('Please fill all fields');
            return;
        }
        if (suburb.trim() === '') {
            setError('Please fill all fields');
            return;
        }
        if (country.trim() === '') {
            setError('Please fill all fields');
            return;
        }
        if (email.trim() === '') {
            setError('Please fill all fields');
            return;
        }
        if (num.trim() === '') {
            setError('Please fill all fields');
            return;
        }

        let days = calculateDays();
        if (days == 0) days += 1;
        if (days < 0) {
            setError('Drop off date must be after pickup date!');
            return;
        }

        if (pickupDate.toLocaleDateString() == dropOffDate.toLocaleDateString()) {
            if (calculateTime() <= 0) {
                setError('Drop off time must be after pickup time!');
                return;
            }
            console.log("time: ", calculateTime());
        }

        let data = {
            firstName: fname,
            lastName: lname,
            address: address,
            apt: apt,
            suburb: suburb,
            country: country,
            email: email,
            number: num,
            pickupDate: moment(pickupDate).format('D/M/YYYY'),
            pickupTime: pickupTime,
            dropOffDate: moment(dropOffDate).format('D/M/YYYY'),
            dropOffTime: dropOffTime,
            amount: days,
            uri: uri,
        }

        //props.onNext(data);

        if (trolley.length > 0) {
            setRedirect(true);
            setCustomer(data);
        } else {
            history.push('/');
            setCustomer(data);
        }

    }

    useEffect(() => {

        if (customer.firstName) {
            setFname(customer.firstName);
            setLname(customer.lastName);
            setAddress(customer.address);
            setApt(customer.apt);
            setSuburb(customer.suburb);
            setCountry(customer.country);
            setEmail(customer.email);
            setNumber(customer.number);

            let d1 = convertToJsDate2(customer.pickupDate);
            let d2 = convertToJsDate2(customer.dropOffDate);

            setPickupDate(d1);
            setPickupTime(customer.pickupTime);
            setDropOffDate(d2);
            setDropOffTime(customer.dropOffTime);
        }
    }, []);

    useEffect(() => {
        if (redirect) {
            history.push('/cart');
        }
    }, [customer])



    return (
        <div className="home-container">
            <div className="home-header">
                <img src={require('../assets/eventlogo.png')} />
            </div>

            <div className="home-prompt">
                <p>Please fill out all your customer information to continue</p>
                {/* <button className="btn-primary" onClick={() => { setShowForm(true) }}>Continue</button> */}
            </div>


            <div className="home-form">
                <CustomInput
                    type="text"
                    placeholder="First Name"
                    label="First Name"
                    id="fname"
                    classID="a"
                    value={fname}
                    onChange={updateFname}
                />
                <CustomInput
                    type="text"
                    placeholder="Last Name"
                    label="Last Name"
                    id="lname"
                    classID="b"
                    value={lname}
                    onChange={updateLname}
                />
                <CustomInput
                    type="text"
                    placeholder="Address"
                    label="Address"
                    id="address"
                    classID="c"
                    value={address}
                    onChange={updateAddress}
                />
                <CustomInput
                    type="text"
                    placeholder="Apt"
                    label="Apt"
                    id="apt"
                    classID="d"
                    value={apt}
                    onChange={updateApt}
                />
                <CustomInput
                    type="text"
                    placeholder="Suburb"
                    label="Suburb"
                    id="suburb"
                    classID="e"
                    value={suburb}
                    onChange={updateSuburb}
                />
                <CustomInput
                    type="text"
                    placeholder="City"
                    label="City"
                    id="city"
                    classID="f"
                    value={country}
                    onChange={updateCountry}
                />
                <CustomInput
                    type="email"
                    placeholder="Email"
                    label="Email"
                    id="email"
                    classID="g"
                    value={email}
                    onChange={updateEmail}
                />
                <CustomInput
                    type="text"
                    placeholder="Mobile Number"
                    label="Mobile Number"
                    id="mobile"
                    classID="h"
                    value={num}
                    onChange={updateNumber}
                />

                <div className="home-pickup">
                    <p>Pickup Date and Time:</p>
                    <DatePicker
                        selected={pickupDate}
                        onChange={date => setPickupDate(date)}
                        customInput={<CustomCalendar />}
                        dateFormat='dd/MM/yyy'
                    />

                    <TimePicker
                        onChange={setPickupTime}
                        value={pickupTime}
                        disableClock={true}
                        className="details-clock"
                    />
                </div>

                <div className="home-dropoff">
                    <p>Drop Off Date and Time:</p>
                    <DatePicker
                        selected={dropOffDate}
                        onChange={date => setDropOffDate(date)}
                        customInput={<CustomCalendar />}
                        dateFormat='dd/MM/yyy'
                    />

                    <TimePicker
                        onChange={setDropOffTime}
                        value={dropOffTime}
                        disableClock={true}
                        className="details-clock"
                    />
                </div>

                <Uploader uploadComplete={url => setUri(url)} />

                <button id="i" className="btn-primary" onClick={validateData} >Next</button>

            </div>

            {error !== '' ? <div className="home-error">{error}</div> : null}
        </div>
    );
}

export default Home;