import { atom } from 'recoil';

const cart = atom({
    key: 'cart',
    default: [],
});

const customerInfo = atom({
    key: 'customerInfo',
    default: {},
});

const adminToken = atom({
    key: 'adminToken',
    default: 'none',
});

const dataHolder = atom({
    key: 'dataHolder',
    default: null,
});

export { cart, customerInfo, adminToken, dataHolder };
