// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import * as firebase from "firebase/app"


// Add the Firebase services that you want to use
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import { FaDove } from "react-icons/fa";
import { Redirect } from "react-router";


// TODO: Replace the following with your app's Firebase project configuration
// For Firebase JavaScript SDK v7.20.0 and later, `measurementId` is an optional field
var firebaseConfig = {
    apiKey: "AIzaSyCvR2DEWI1Mmt5KBwfBN0m2SyTqzQKOPfA",
    authDomain: "mie-events.firebaseapp.com",
    databaseURL: "https://mie-events.firebaseio.com",
    projectId: "mie-events",
    storageBucket: "mie-events.appspot.com",
    messagingSenderId: "671613776640",
    appId: "1:671613776640:web:85bc41fdfc72b41145f9e5",
    measurementId: "G-59DN78ZZXD"
};


// Initialize Firebase
firebase.initializeApp(firebaseConfig);


export function getDatabase() {
    return firebase.firestore();
}

export function getStorageRef() {
    return firebase.storage().ref();
}

export function signIn(email, password, success, fail) {
    firebase.auth().signInWithEmailAndPassword(email, password)
        .then(cred => {
            success(cred);
        })
        .catch(error => {
            fail(error);
        });
}

export function setAuthObserver(signIn, signOut) {
    firebase.auth().onAuthStateChanged(user => {
        if (user) {
            signIn(user);
        } else {
            signOut(user);
        }
    });
}

export function signOut(success, fail) {
    firebase.auth().signOut().then(() => {
        success();
    }).catch((error) => {
        fail();
    });
}

export async function getUser() {
    let user = await firebase.auth().currentUser;
    if (user) {
        console.log("User info: ", user);
        return user.uid;
    }

    return null;
}

export function placeOrder(data, success, fail) {
    firebase.firestore()
        .collection('orders')
        .add({
            ...data,
            created: firebase.firestore.FieldValue.serverTimestamp()
        })
        .then(() => {
            success();
        })
        .catch(error => {
            fail(error);
        });
}

export function toDate(timestamp) {
    return timestamp.toDate();
}

export async function getCurrentOrderId() {
    const ref = firebase.firestore().collection('globals').doc('orderIds');

    const docSnap = await ref.get();
    let id = -1;
    if (docSnap.exists) {
        id = docSnap.data().orderId;
        console.log("order id: ", docSnap.data());
        //increment
        ref.update({
            orderId: firebase.firestore.FieldValue.increment(1)
        })
    }

    return id;


}
