import React, { useState } from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Navbar from './components/Navbar';
import Home from './screens/Home';
import Products from './screens/Products';
import Details from './screens/Details';
import Footer from './components/Footer';
import ReviewOrder from './screens/ReviewOrder';
import Finalise from './screens/Finalise';
import Login from './screens/Login';
import Orders from './screens/Orders';
import Edit from './screens/Edit';
import Terms from './screens/Terms';
import { RecoilRoot } from 'recoil';


function App() {

  return (
    <RecoilRoot>
      <Router>
        <div className="App" >
          <Navbar />
          <Switch>
            <Route path="/" exact >
              <Products />
            </Route>

            <Route path="/customer" exact >
              <Home />
            </Route>

            <Route path="/products/:id">
              <Details />
            </Route>
            <Route path="/cart">
              <ReviewOrder />
            </Route>
            <Route path="/finalise">
              <Finalise />
            </Route>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/orders">
              <Orders />
            </Route>
            <Route path="/edit">
              <Edit />
            </Route>
            <Route path="/terms">
              <Terms />
            </Route>
          </Switch>

          <Footer />
        </div>
      </Router >
    </RecoilRoot>
  );
}

export default App;
