import React, { useState } from 'react';
import "../css/OrderItem.css";
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import { toDate, getDatabase } from '../api/FirebaseAPI';
import axios from 'axios';
import BeatLoader from "react-spinners/BeatLoader";
import { dataHolder } from '../States';
import { useRecoilState } from 'recoil';
import { useHistory } from 'react-router-dom';

const OrderItem = ({ data }) => {

    const [cusCollapse, setCusCollapse] = useState(true);
    const [ordCollapse, setOrdCollapse] = useState(true);
    const [loading, setLoading] = useState(false);

    const [response, setResponse] = useState();
    const [temp, setTemp] = useRecoilState(dataHolder);

    let history = useHistory();

    const toggleCusCollapse = () => {
        setCusCollapse(!cusCollapse);
    }

    const toggleOrdCollapse = () => {
        setOrdCollapse(!ordCollapse);
    }

    const getTotalPrice = () => {
        let sum = 0;
        data.cart.forEach(item => {
            sum += item.totalPrice;
        });

        sum += parseFloat(data.bond) + parseFloat(data.delivery);
        if (data.discount) sum -= parseFloat(data.discount);
        return sum;
    }

    const confirmation = (e) => {
        setLoading(true);
        e.preventDefault();

        axios.post('https://makeiteasy.co.nz/finalise/index.php', data)
            .then(response => {
                console.log("Axios response: ", response.data);
                setResponse(response.data);
                setLoading(false);
                updateConfirmationStatus();
            }).catch(error => {
                console.log("Axios error: ", error);
            });

    }

    const complete = (e) => {
        e.preventDefault();
        const db = getDatabase();

        db.collection('orders').doc(data.id).update({
            completed: true
        });



        console.log(`order = ${data.id} completed`);
    }

    const reject = (e) => {
        e.preventDefault();
        var result = window.confirm("Are you sure you wish to reject this order? The result cannot be undone.");

        if (result) {
            let db = getDatabase();
            db.collection('orders').doc(data.id).delete();
        }

    }

    const updateConfirmationStatus = () => {
        const db = getDatabase();

        db.collection('orders').doc(data.id).update({
            confirmed: true
        });
    }

    const editItem = () => {
        setTemp(data);
        history.push('/edit');

        console.log("data: ", data);
    }

    return (
        <div className="orderitem-container">
            <div className="orderitem-customer-details">
                <div className="orderitem-cs-dt-header" onClick={toggleCusCollapse}>
                    <h4>Customer Details</h4>
                    {cusCollapse ? <AiFillCaretUp /> : <AiFillCaretDown />}
                    <p className="orderitem-date"><span onClick={editItem} className="orderitem-edit">Edit</span>Created: {toDate(data.created).toDateString()}</p>
                </div>

                <div className="orderitem-cs-dt-item">
                    <h5>First Name: </h5>
                    <p>{data.firstName}</p>
                </div>

                <div className="orderitem-cs-dt-item">
                    <h5>Last Name: </h5>
                    <p>{data.lastName}</p>
                </div>

                <div className={!cusCollapse ? "orderitem-cs-dt-item full" : "orderitem-cs-dt-item full hidden"}>
                    <h5>Address: </h5>
                    <p>{data.address} {data.apt}</p>
                </div>

                <div className={!cusCollapse ? "orderitem-cs-dt-item " : "orderitem-cs-dt-item hidden"}>
                    <h5>Suburb: </h5>
                    <p>{data.suburb}</p>
                </div>

                <div className={!cusCollapse ? "orderitem-cs-dt-item " : "orderitem-cs-dt-item hidden"}>
                    <h5>City: </h5>
                    <p>{data.country}</p>
                </div>

                <div className={!cusCollapse ? "orderitem-cs-dt-item " : "orderitem-cs-dt-item hidden"}>
                    <h5>Email: </h5>
                    <p>{data.email}</p>
                </div>

                <div className={!cusCollapse ? "orderitem-cs-dt-item " : "orderitem-cs-dt-item hidden"}>
                    <h5>Phone: </h5>
                    <p>{data.number}</p>
                </div>

                <div className={!cusCollapse ? "orderitem-cs-dt-item " : "orderitem-cs-dt-item hidden"}>
                    <h5>Pickup date & time: </h5>
                    <p>{data.pickupDate} | {data.pickupTime}</p>
                </div>

                <div className={!cusCollapse ? "orderitem-cs-dt-item " : "orderitem-cs-dt-item hidden"}>
                    <h5>Drop off date & time: </h5>
                    <p>{data.dropOffDate} | {data.dropOffTime}</p>
                </div>

                <div className="orderitem-cs-dt-header" onClick={toggleOrdCollapse}>
                    <h4>Order Details</h4>
                    {ordCollapse ? <AiFillCaretUp /> : <AiFillCaretDown />}
                </div>

                <div className={"orderitem-cs-dt-item full "}>
                    <h5>Number of cart Items: </h5>
                    <p>{data.cart.length}</p>
                </div>

                <div className={!ordCollapse ? "orderitem-cs-dt-item full table" : "orderitem-cs-dt-item full table hidden"}>
                    <h5>Items: </h5>
                    <table className="ordertable">
                        <tr>
                            <th>Title</th>
                            <th>Quantity</th>
                            <th>Price</th>
                        </tr>
                        {data.cart.map(item => {
                            return (
                                <tr key={item.id}>
                                    <td>{item.title}</td>
                                    <td>{item.quantity}</td>
                                    <td>${item.totalPrice}</td>
                                </tr>
                            );
                        })}
                    </table>

                </div>

                <div className={"orderitem-cs-dt-item full "}>
                    <h5>Delivery Price: </h5>
                    <p>${data.delivery}</p>
                </div>

                <div className={"orderitem-cs-dt-item full "}>
                    <h5>Bond: </h5>
                    <p>${data.bond}</p>
                </div>

                {data.discount && <div className={"orderitem-cs-dt-item full "}>
                    <h5>Discount: </h5>
                    <p>${data.discount}</p>
                </div>}

                <div className={"orderitem-cs-dt-item full "}>
                    <h5>Total Price: </h5>
                    <p>${getTotalPrice()}</p>
                </div>

                <div className="orderitem-buttons" >
                    <button disabled={data.confirmed} className={data.confirmed ? "btn-primary disabled" : "btn-primary"} onClick={confirmation}>Confirm</button>
                    <button disabled={data.confirmed && !data.completed ? false : true} className={data.confirmed && !data.completed ? "btn-primary" : "btn-primary disabled"} onClick={complete}>Complete</button>
                    <button className="btn-secondary" onClick={reject}>Reject</button>
                </div>

                <div className="orderitem-response">
                    {loading ? <BeatLoader
                        size={5}
                        color={"#1e73be"}
                        loading={loading}
                    /> : response}
                </div>

                <div className="orderitem-id full">
                    <p>id: {data.id}</p>
                </div>

            </div>
        </div>
    );
}

export default OrderItem;