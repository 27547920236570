import React, { useState, useEffect } from 'react';
import '../css/ReviewOrder.css';
import { useHistory } from 'react-router-dom';
import { FaEdit, FaCheck } from 'react-icons/fa';
import { useRecoilState } from 'recoil';
import { customerInfo, cart, adminToken } from '../States';
import CustomCalendar2 from '../components/CustomCalendar2';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from 'react-time-picker';
import Quantity from '../components/Quantity';
import { convertToJsDate, convertToJsDate2 } from '../util/Dates';
import moment from 'moment';

const ReviewOrder = (props) => {

    const [editing, setEditing] = useState(false);
    const [fname, setFname] = useState();
    const [lname, setLname] = useState();
    const [address, setAddress] = useState();
    const [apt, setApt] = useState();
    const [suburb, setSuburb] = useState();
    const [city, setCity] = useState();
    const [email, setEmail] = useState();
    const [number, setNumber] = useState();
    const [error, setError] = useState('');
    const [pickupDate, setPickupDate] = useState(new Date());
    const [pickupTime, setPickupTime] = useState('10:00');
    const [dropOffDate, setDropOffDate] = useState(new Date());
    const [dropOffTime, setDropOffTime] = useState('10:00');
    const [redirect, setRedirect] = useState(false);
    const [bond, setBond] = useState(100);
    const [discount, setDiscount] = useState(0);
    const [uri, setUri] = useState(null);
    const [subtotal, setSubtotal] = useState(0);

    const [addingDelivery, setAddingDelivery] = useState(false);
    const [deliveryPrice, setDeliveryPrice] = useState(0);
    const [isDelivery, setIsDelivery] = useState(false);

    const [addingBond, setAddingBond] = useState(false);
    const [addingDiscount, setAddingDiscount] = useState(false);

    const [selectedItems, setSelectedItems] = useState([]);


    //global state
    const [customer, setCustomer] = useRecoilState(customerInfo);
    const [trolley, setTrolley] = useRecoilState(cart);
    const [admin, setAdmin] = useRecoilState(adminToken);

    let history = useHistory();

    const calculateSubTotal = () => {
        let total = 0;
        trolley.forEach(item => {
            if (item.eaches) {
                total += item.quantity * item.price;
            } else {
                total += item.quantity * customer.amount * item.price;
            }

        });

        if (isDelivery) total += parseInt(deliveryPrice);
        total += parseInt(bond);
        total -= parseInt(discount);

        setSubtotal(total);
        console.log("total", total);
    }

    if (!customer.firstName) {
        alert("Please fill out customer details in home screen!");
        history.push('/');
    }

    const onEdit = () => {
        setEditing(true);

        setFname(customer.firstName);
        setLname(customer.lastName);
        setAddress(customer.address);
        setApt(customer.apt);
        setCity(customer.country);
        setSuburb(customer.suburb);
        setEmail(customer.email);
        setNumber(customer.number);

        console.log("input date: ", customer.dropOffDate)

        let d1 = convertToJsDate2(customer.pickupDate);
        let d2 = convertToJsDate2(customer.dropOffDate);

        console.log("Debug date: ", d1);
        console.log("Debug date: ", d2);

        setPickupDate(new Date(d1));
        setPickupTime(customer.pickupTime);
        setDropOffDate(new Date(d2));
        setDropOffTime(customer.dropOffTime);
        setUri(customer.uri);
    }

    const onSave = () => {
        validateData();
    }

    const updateFname = (e) => {
        e.preventDefault();
        setFname(e.target.value);
        setError('');
    }

    const updateLname = (e) => {
        e.preventDefault();
        setLname(e.target.value);
        setError('');
    }

    const updateAddress = (e) => {
        e.preventDefault();
        setAddress(e.target.value);
        setError('');
    }

    const updateApt = (e) => {
        e.preventDefault();
        setApt(e.target.value);
        setError('');
    }

    const updateSuburb = (e) => {
        e.preventDefault();
        setSuburb(e.target.value);
        setError('');
    }

    const updateCountry = (e) => {
        e.preventDefault();
        setCity(e.target.value);
        setError('');
    }

    const updateEmail = (e) => {
        e.preventDefault();
        setEmail(e.target.value);
        setError('');
    }

    const updateNumber = (e) => {
        e.preventDefault();
        setNumber(e.target.value);
        setError('');
    }

    const calculateDays = () => {
        let days = dropOffDate - pickupDate;
        days = Math.round(days / 24 / 60 / 60 / 1000);

        return days;
    }

    const calculateTime = () => {
        let p1 = pickupTime.split(':');
        let pH = parseInt(p1[0]);
        let pM = parseInt(p1[1]);
        let pTime = (pH * 60) + pM;

        let d1 = dropOffTime.split(':');
        let dH = parseInt(d1[0]);
        let dM = parseInt(d1[1]);
        let dTime = (dH * 60) + dM;

        return dTime - pTime;
    }

    const validateData = () => {
        if (fname.trim() === '') {
            setError('Please fill all fields');
            return;
        }
        if (lname.trim() === '') {
            setError('Please fill all fields');
            return;
        }
        if (address.trim() === '') {
            setError('Please fill all fields');
            return;
        }
        if (suburb.trim() === '') {
            setError('Please fill all fields');
            return;
        }
        if (city.trim() === '') {
            setError('Please fill all fields');
            return;
        }
        if (email.trim() === '') {
            setError('Please fill all fields');
            return;
        }
        if (number.trim() === '') {
            setError('Please fill all fields');
            return;
        }


        let days = calculateDays();
        if (days == 0) days += 1;
        if (days < 0) {
            setError('Drop off date must be after pickup date!');
            return;
        }

        if (pickupDate.toLocaleDateString() == dropOffDate.toLocaleDateString()) {
            if (calculateTime() <= 0) {
                setError('Drop off time must be after pickup time!');
                return;
            }
            console.log("time: ", calculateTime());
        }

        let data = {
            firstName: fname,
            lastName: lname,
            address: address,
            apt: apt,
            suburb: suburb,
            country: city,
            email: email,
            number: number,
            pickupDate: moment(pickupDate).format('D/M/YYYY'),
            pickupTime: pickupTime,
            dropOffDate: moment(dropOffDate).format('D/M/YYYY'),
            dropOffTime: dropOffTime,
            amount: days,
            uri: uri,
        }

        setCustomer(data);
        setEditing(false);
    }

    const finalise = () => {
        if (editing) return;

        let newTrolley = [...trolley];

        for (let i = 0; i < newTrolley.length; i++) {
            let tp;
            if (newTrolley[i].eaches) {
                tp = newTrolley[i].quantity * newTrolley[i].price;
            } else {
                tp = newTrolley[i].quantity * customer.amount * newTrolley[i].price;
            }
            let newItem = {
                ...newTrolley[i],
                totalPrice: tp,
            };

            newTrolley[i] = newItem;
        }

        // console.log("new trolley", newTrolley);
        let dp = isDelivery ? deliveryPrice : 0;
        dp = parseInt(dp);
        let newCustomer = { ...customer, bond: bond, delivery: dp, discount: discount };


        setCustomer(newCustomer);
        setTrolley(newTrolley);
        setRedirect(true);


    }

    useEffect(() => {
        if (redirect) {
            //only redirect once trolley has been updated
            history.push('/finalise');
        }
        calculateSubTotal();
    }, [trolley, bond, deliveryPrice, discount]);

    const deliveryToggle = () => {
        if (addingBond || addingDiscount) {
            setAddingBond(false);
            setAddingDelivery(false);
        }
        setAddingDelivery(!addingDelivery);
        setIsDelivery(true);
    }

    const bondToggle = () => {
        if (addingDelivery || addingDiscount) {
            setAddingDelivery(false);
            setAddingDiscount(false);
        }
        setAddingBond(!addingBond);
    }

    const discountToggle = () => {
        if (addingBond || addingDelivery) {
            setAddingBond(false);
            setAddingDelivery(false);
        }
        setAddingDiscount(!addingDiscount);
    }

    const selectRow = (evt, id) => {

        //check if item is already selected
        if (selectedItems.includes(id)) {
            //remove from array
            let index = selectedItems.indexOf(id);
            selectedItems.splice(index, 1);
            let temparray = Array.from(selectedItems);
            setSelectedItems(temparray);
        } else {
            //add to array
            selectedItems.push(id);
            let temparray = Array.from(selectedItems);
            setSelectedItems(temparray);
        }

    }

    const isSelected = (id) => {
        if (selectedItems.includes(id)) return true;

        return false;
    }

    const deleteSelected = () => {
        let array = [...trolley];

        selectedItems.forEach(id => {
            let index;
            for (index = 0; 0 < array.length; index++) {
                if (array[index].id === id) {
                    console.log("found id: ", array[index].id);
                    array.splice(index, 1);
                    break;
                }
            }
        })

        setTrolley(array);
    }

    const onRemove = (id) => {


    }

    return (
        <div className="review-container">
            <div className="review-banner">
                <h2>Shopping Cart</h2>
            </div>

            {customer.firstName && !editing && <div className="review-user-details">
                <h4 className="review-heading">Customer Details</h4>
                <p className="review-name">{customer.firstName} {customer.lastName}</p>
                <p className="review-address">{customer.address} {customer.apt}</p>
                <p className="review-suburb">{customer.suburb}</p>
                <p className="review-country">{customer.country}</p>
                <p className="review-email">{customer.email}</p>
                <p className="review-number">{customer.number}</p>
                <p className="review-id">ID: {customer.uri ? "true" : "no id"}</p>
                <p className="review-pickup">Pickup: {customer.pickupDate} | {customer.pickupTime}</p>
                <p className="review-dropoff">Drop Off: {customer.dropOffDate} | {customer.dropOffTime}</p>
                <div className="review-edit-btn" onClick={onEdit}>
                    <p>Edit</p> <FaEdit />
                </div>
            </div>}

            {editing ? <div className="review-user-details">
                <h4 className="review-heading">Customer Details</h4>
                <div className="review-name"><input type="text" value={fname} placeholder="First Name" onChange={updateFname} /> <input type="text" value={lname} placeholder="Last Name" onChange={updateLname} /></div>
                <div className="review-address"><input type="text" value={address} placeholder="Address" onChange={updateAddress} /> <input type="text" value={apt} placeholder="Apt" onChange={updateApt} /></div>
                <div className="review-suburb"><input type="text" value={suburb} placeholder="Suburb" onChange={updateSuburb} /></div>
                <div className="review-country"><input type="text" value={city} placeholder="Country" onChange={updateCountry} /></div>
                <div className="review-email"><input type="text" value={email} placeholder="Email" onChange={updateEmail} /></div>
                <div className="review-number"><input type="text" value={number} placeholder="Number" onChange={updateNumber} /></div>
                <div className="review-pickup">
                    <p>Pickup Date and Time:</p>
                    <DatePicker
                        selected={pickupDate}
                        onChange={date => setPickupDate(date)}
                        customInput={<CustomCalendar2 />}
                        dateFormat='dd/MM/yyy'
                    />

                    <TimePicker
                        onChange={setPickupTime}
                        value={pickupTime}
                        disableClock={true}
                        className="review-clock"
                    />
                </div>

                <div className="review-dropoff">
                    <p>Drop Off Date and Time:</p>
                    <DatePicker
                        selected={dropOffDate}
                        onChange={date => setDropOffDate(date)}
                        customInput={<CustomCalendar2 />}
                        dateFormat='dd/MM/yyy'
                    />

                    <TimePicker
                        onChange={setDropOffTime}
                        value={dropOffTime}
                        disableClock={true}
                        className="review-clock"
                    />
                </div>
                <div className="review-edit-btn" onClick={onSave}>
                    <p>Save</p>
                </div>
                {error !== '' ? <p>{error}</p> : null}
            </div> : null}

            <div className="review-controls">
                <div>
                    <button className={admin !== 'none' ? "btn-primary" : "btn-primary hide"} onClick={deliveryToggle}>{addingDelivery ? "Save" : "Add Delivery"}</button>
                    <button className={admin !== 'none' ? "btn-primary" : "btn-primary hide"} onClick={bondToggle}>Change Bond</button>
                    <button className={admin !== 'none' ? "btn-primary" : "btn-primary hide"} onClick={discountToggle}>{addingDiscount ? "Save" : "Discount"}</button>
                    <button className="btn-primary" onClick={deleteSelected}>Delete Selected</button>
                    {isDelivery && <button className={addingDelivery ? "btn-primary editing" : "btn-primary"} onClick={() => setIsDelivery(false)} disabled={addingDelivery ? true : false}>Remove Delivery</button>}
                </div>

                {addingDelivery &&
                    <div className="review-delivery-input">
                        <label htmlFor="delivery-input">Enter delivery price</label>
                        <input type="number" id="delivery-input" value={deliveryPrice} onChange={e => setDeliveryPrice(e.target.value)} />
                    </div>
                }

                {addingBond &&
                    <div className="review-bond-input">
                        <label htmlFor="bond-input">Enter bond price</label>
                        <input type="number" id="bond-input" value={bond} onChange={e => setBond(e.target.value)} />
                    </div>
                }

                {addingDiscount &&
                    <div className="review-discount-input">
                        <label htmlFor="discount-input">Enter Discount Amount</label>
                        <input type="number" id="discount-input" value={discount} onChange={e => setDiscount(e.target.value)} />
                    </div>
                }



            </div>

            <div className="review-productarea">
                <table>
                    <thead>
                        <tr>
                            <th className="review-table-header">
                                Product
                            </th>
                            <th className="review-table-header">
                                Description
                            </th>
                            <th className="review-table-header">
                                Quantity
                            </th>
                            <th className="review-table-header">
                                Days
                            </th>
                            <th className="review-table-header">
                                Unit
                            </th>
                            <th className="review-table-header">
                                Total
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {trolley.map(item => {
                            return (
                                <tr >
                                    <td className="review-table-image" onClick={evt => { selectRow(evt, item.id) }}><img src={item.image} /> </td>
                                    <td className="review-table-desc" onClick={evt => { selectRow(evt, item.id) }}>
                                        <h5>{item.title}</h5>
                                        <p>{item.subtitle}</p>

                                        {isSelected(item.id) ? <FaCheck className="check-style" /> : null}
                                    </td>
                                    <td className="review-table-quantity">
                                        <Quantity
                                            item={item}
                                        />
                                    </td>
                                    <td className="review-table-quantity">
                                        <h4>{item.eaches ? "N/A" : customer.amount}</h4>
                                    </td>
                                    <td className="review-table-unit">
                                        <h4>${item.price}</h4>
                                    </td>
                                    <td className="review-table-total">
                                        <h4>${item.eaches ? item.quantity * item.price : item.quantity * customer.amount * item.price}</h4>
                                    </td>
                                </tr>
                            );
                        })}

                        <tr>
                            <td></td>
                            <td className="review-table-desc">
                                <h5>Mandatory Bond</h5>
                                <p>This bond will be refunded on return of the items</p>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className="review-table-total"><h4>${bond}</h4></td>
                        </tr>

                        {isDelivery && <tr>
                            <td></td>
                            <td className="review-table-desc">
                                <h5>Delivery Fee</h5>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className="review-table-total"><h4>${deliveryPrice}</h4></td>
                        </tr>}

                        {discount > 0 && <tr>
                            <td></td>
                            <td className="review-table-desc">
                                <h5>Discount</h5>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td className="review-table-total"><h4>${discount}</h4></td>
                        </tr>}
                    </tbody>
                </table>
            </div>

            {trolley && <div className="review-subtotal">
                <button className={editing ? "btn-primary editing" : "btn-primary"} onClick={finalise}>Place Order</button>

                <div class="review-subtotal-wrapper">
                    <h3>Subtotal: </h3>
                    <p>${subtotal}</p>
                </div>

            </div>}
        </div>
    );
}

export default ReviewOrder;