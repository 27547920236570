import React, { useState, useEffect } from 'react';
import '../css/Details.css';
import { getDatabase } from '../api/FirebaseAPI';
import { useParams } from 'react-router-dom';
import CustomCalendar from '../components/CustomCalendar';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from 'react-time-picker';
import { useRecoilState } from 'recoil';
import { cart } from '../States';

const Details = (props) => {
    const [product, setProduct] = useState();
    const [counter, setCounter] = useState(1);
    const [pickupDate, setPickupDate] = useState(new Date());
    const [pickupTime, setPickupTime] = useState('10:00');
    const [dropOffDate, setDropOffDate] = useState(new Date());
    const [dropOffTime, setDropOffTime] = useState('10:00');
    const [error, setError] = useState('');

    //global state
    const [trolley, setTrolley] = useRecoilState(cart);

    let { id } = useParams();

    const minus = () => {
        if (counter <= 0) return;
        setCounter(counter - 1);
    }

    const plus = () => {
        setCounter(counter + 1);
    }

    const addToCart = () => {

        let item = {
            ...product,
            quantity: counter,
            pos: getCartPos(),
        };

        //check if item already exsits
        for (let i = 0; i < trolley.length; i++) {
            if (trolley[i].productCode === item.productCode) {
                let newTrolley = [...trolley];
                item.quantity += trolley[i].quantity;
                newTrolley[i] = item;
                setTrolley(newTrolley);
                return;
            }
        }

        setTrolley([...trolley, item]);
    }

    const getCartPos = () => {
        let pos = 1;
        if (trolley.length > 0) {
            pos = trolley[trolley.length - 1].pos + 1;
        }

        return pos;
    }

    useEffect(() => {

        let ref = getDatabase().collection('products').doc(id);

        ref.get().then((doc) => {
            if (doc.exists) {
                setProduct(doc.data());
            } else {
                console.log("No such document!");
            }


        }).catch((error) => {
            console.log("Error getting document:", error);
        });


    }, [])

    if (!product) {
        return (<div>
        </div>);
    }

    return (
        <div className="details-container">
            <div className="details-banner">
                <h2>{product.title}</h2>
            </div>
            <div className="details-panel">
                <img src={product.image} />
                <div className="details-item">
                    <div className="details-part1"><h4>{product.title}</h4></div>
                    <div className="details-part2">
                        <h5>${product.price}</h5>
                        <p>{product.subtitle}</p>
                    </div>
                    <div className="details-part3">
                        <div className="details-cart-input">
                            <div className="details-counter">
                                <div className="minus" onClick={minus}>-</div>
                                <div className="count">{counter}</div>
                                <div className="plus" onClick={plus}>+</div>
                            </div>
                            <button className="btn-primary" onClick={addToCart}>ADD TO CART</button>
                        </div>


                        {error !== '' ? <p className="detail-error">{error}</p> : null}

                    </div>
                    <div className="details-part4">
                        <span><p>Catergory:</p> {product.category}</span>
                        <span><p>Tags:</p> {product.tags && product.tags.map((item) => {
                            return (`${item}, `);
                        })}</span>
                    </div>
                </div>
            </div>

            <div className="details-desc">
                <div className="details-desc-heading">
                    <h3>DESCRIPTION</h3>
                </div>
                <h5>Description</h5>
                <p>{product.description}</p>
            </div>
        </div>
    );
}

export default Details;