import React, { useState, useEffect } from 'react';
import '../css/Quantity.css';
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai';
import { useRecoilState } from 'recoil';
import { cart } from '../States';

const Quantity = (props) => {
    const [trolley, setTrolley] = useRecoilState(cart);


    const increaseQuantity = () => {
        let newTrolley = [...trolley];
        let newItem = { ...props.item };
        newItem.quantity += 1;

        for (let i = 0; i < newTrolley.length; i++) {
            if (newTrolley[i].pos === props.item.pos) {
                newTrolley[i] = newItem;
                break;
            }
        }


        setTrolley(newTrolley);
    }

    const decreaseQuantity = () => {
        if (props.item.quantity <= 1) return;
        let newTrolley = [...trolley];
        let newItem = { ...props.item };
        newItem.quantity -= 1;

        for (let i = 0; i < newTrolley.length; i++) {
            if (newTrolley[i].pos === props.item.pos) {
                newTrolley[i] = newItem;
                break;
            }
        }


        setTrolley(newTrolley);
    }


    return (
        <div className="quantity-container">
            <div className="quantity-up" onClick={increaseQuantity}>
                <AiFillCaretUp />
            </div>
            <div className="quantity-value">
                {props.item.quantity}
            </div>
            <div className="quantity-down" onClick={decreaseQuantity}>
                <AiFillCaretDown />
            </div>

        </div>
    );
}

export default Quantity;